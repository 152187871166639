sl-button[variant="tiny"]::part(base) {
  @apply bg-transparent hover:bg-compassionate-seafoam/20
    border-harmony-haze hover:border-compassionate-seafoam
    duration-300;
}

sl-button[variant="tiny"]::part(label) {
  @apply text-harmony-haze hover:text-compassionate-seafoam
  text-sm
  font-sans
  font-normal
  pl-4;
}
