/* If you need to add @import statements, do so up here */

@import "jit-refresh.css"; /* triggers frontend rebuilds */

/* Set up Tailwind imports */
@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
}

:not(:defined) {
  visibility: hidden;
}

@font-face {
  font-family: "fallback for Epilogue";
  src: local("Arial");
  ascent-override: 79%;
  descent-override: 23.5%;
  line-gap-override: 0%;
}

@font-face {
  font-family: "fallback for DM Sans";
  src: local("Arial");
  ascent-override: 99.2%;
  descent-override: 31%;
  line-gap-override: 0%;
}

body.page {
  & a {
    @apply hover:underline underline-offset-4 decoration-2;
  }

  & h2 {
    @apply text-3xl;
  }

  & ul {
    @apply list-disc list-outside my-4 ml-4;
  }

  & img {
    @apply max-w-full mx-auto;
  }
}

.webp {
  & .bg-dark-texture {
    @apply bg-dark-texture-webp;
  }

  & .bg-medium-texture {
    @apply bg-medium-texture-webp;
  }
}

.bg-hued-cobalt {
  --hued-background-color: theme(colors.hued-cobalt);
}

.bg-empowered-evergreen {
  --hued-background-color: theme(colors.empowered-evergreen);
}

.no-webp {
  & .bg-dark-texture {
    @apply bg-dark-texture-jpg;
  }

  & .bg-medium-texture {
    @apply bg-medium-texture-jpg;
  }
}

@layer utilities {
  .in-progress {
    @apply border-2 border-green-500;
  }

  .todo {
    @apply border-2 border-red-500;
  }

  .bg-todo {
    background: repeating-linear-gradient(
      45deg,
      white,
      white 10px,
      yellow 10px,
      yellow 20px
    );
  }
}
