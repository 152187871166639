course .prose, course .prose-invert {
  & ul {
    @apply list-disc;
  }

  & li {
    @apply leading-tight;
  }

  & p {
    @apply leading-tight text-lg tablet:text-2xl;
  }
}
