.webp .search_form {
  background: url(/images/hued_banner.webp) top left / contain, no-repeat,
    radial-gradient(circle at 10% 10%, #556d83, #36495c), #36495c;
  background-repeat: no-repeat;
}

.no-webp .search_form {
  background: url(/images/hued_banner.png) top left / contain, no-repeat,
    radial-gradient(circle at 10% 10%, #556d83, #36495c), #36495c;
  background-repeat: no-repeat;
}

.search_form {
  /* used by sl-radio-button for hover and checked backgrounds */
  --sl-color-primary-600: #996959;

  & sl-radio-button::part(button) {
    @apply bg-white text-black;
  }

  & sl-radio-button::part(button):hover,
  & sl-radio-button::part(button--checked) {
    @apply bg-hued-tan text-white;
  }

  & sl-button::part(base) {
    @apply bg-[#3a211e] border-hued-tan text-white uppercase;
  }
}
