.navbar {
  --sl-font-size-medium: theme(fontSize.xl);
  --sl-font-weight-normal: theme(fontWeight.bold);
  --sl-color-neutral-100: theme(colors.harmony-haze);
  --sl-panel-background-color: var(--hued-background-color);

  & sl-button[variant="text"]::part(base) {
    @apply hover:text-compassionate-seafoam font-medium text-xl;
  }

  & sl-menu-item::part(base) {
    @apply rounded-xl;
  }

  & sl-drawer::part(close-button) {
    @apply text-2xl stroke-harmony-haze stroke-1;
  }
}
