sl-button.page::part(base) {
  @apply bg-harmony-haze  border-empowered-evergreen  hover:bg-soothing-slate/50 motion-safe:hover:scale-110;
}

sl-button.page::part(label) {
  @apply text-grounded-slate p-0;
}

sl-button.page.current::part(base) {
  @apply bg-soothing-slate hover:bg-soothing-slate/50;
}
