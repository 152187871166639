sl-button::part(base) {
  --sl-spacing-medium: 0px;
  @apply bg-transparent
    motion-safe:transition-all
    motion-safe:duration-300;
}

/* Hides the button prefix so that the justfiy-between works */
sl-button::part(prefix) {
  @apply hidden;
}

sl-button::part(label) {
  @apply py-2
    pl-6
    pr-4;
}

sl-button[variant="primary"]::part(base) {
  @apply bg-harmony-haze
    hover:bg-compassionate-seafoam
    border-grounded-slate;
}

sl-button[variant="primary"]::part(label) {
  @apply text-grounded-slate
  font-sans
  font-medium
  text-xl;
}

sl-button[variant="primary"]::part(suffix) {
  @apply text-grounded-slate
    text-2xl;
}

sl-button[variant="secondary"]::part(base) {
  @apply bg-transparent hover:bg-compassionate-seafoam/20
    border-harmony-haze hover:border-compassionate-seafoam
    text-harmony-haze hover:text-compassionate-seafoam
    tablet:justify-between;
}

sl-button[variant="secondary"]::part(label) {
  @apply font-sans
    font-medium
    text-xl;
}

sl-button[variant="secondary"]::part(suffix) {
  @apply text-2xl
      font-medium
      font-sans
      pr-2;
}

sl-button[variant="text"]::part(base) {
  @apply bg-transparent
    text-harmony-haze
    px-0
    text-base
    font-normal;
}

sl-button[variant="text"]::part(caret) {
  @apply text-harmony-haze hover:text-compassionate-seafoam;
}

sl-button[variant="dark"]::part(base) {
  @apply bg-grounded-slate hover:bg-hued-cobalt
   w-auto;
}

sl-button[variant="dark"]::part(label) {
  @apply text-xl
    font-medium
    font-sans
    text-harmony-haze;
}

sl-button[variant="dark"]::part(suffix) {
  @apply text-harmony-haze
    text-2xl;
}

sl-button[variant="transparent"]::part(base) {
  @apply bg-transparent hover:bg-compassionate-seafoam
   border-grounded-slate
   text-grounded-slate hover:text-grounded-slate
   font-medium;
}

sl-button[variant="light-fixed"]::part(base) {
  @apply bg-harmony-haze hover:bg-soothing-slate
   justify-between
   w-[21rem] tablet:w-[25rem];
}

sl-button[variant="light-fixed"]::part(label) {
  @apply text-2xl
    font-medium
    font-sans
    text-grounded-slate
    leading-loose;
}

sl-button[variant="light-fixed"]::part(suffix) {
  @apply text-3xl
    text-grounded-slate
    font-medium
    font-sans
    pr-2;
}

sl-button[variant="dark-tag"]::part(base) {
  --sl-input-height-medium: 2rem;
  @apply border-grounded-slate hover:border-hued-cobalt;
}

sl-button[variant="dark-tag"]::part(label) {
  @apply text-grounded-slate hover:text-hued-cobalt text-sm font-normal font-sans leading-none pl-4;
}

sl-button[variant="light-tag"]::part(base) {
  --sl-input-height-medium: 2rem;
  @apply border-harmony-haze hover:border-compassionate-seafoam hover:bg-compassionate-seafoam/20;
}

sl-button[variant="light-tag"]::part(label) {
  @apply text-harmony-haze hover:text-compassionate-seafoam text-sm font-normal font-sans leading-loose pl-4;
}
