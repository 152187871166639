sl-button.nav::part(label) {
  @apply text-hued-dark-brown 
    whitespace-normal 
    leading-6 
    max-w-xs
    my-auto
    px-2;
}

sl-button.nav::part(base) {
  --sl-input-height-medium: auto;
  @apply border-none text-lg;
}
