sl-badge::part(base) {
  @apply bg-soothing-slate text-grounded-slate text-base font-medium border-0;
}

sl-badge[variant^="light-"]::part(base) {
  @apply bg-harmony-haze;
}

sl-badge[variant="featured"]::part(base) {
  @apply mix-blend-multiply;
}
